import React, { useState, useEffect, useRef } from "react";
import BurgerMenu from "../../components/BurgerMenu/BurgerMenu";
import "./style.css";
import "../../components/BurgerMenu/BurgerMenu.css";
import { getToken } from "../../apiService/apiService";

const Home = () => {
  const [preloaderVisible, setPreloaderVisible] = useState(true);
  const [tokenExists, setTokenExists] = useState<boolean>(false);
  const [currentCarouselIndex, setCurrentCarouselIndex] = useState<number>(0);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const goToCarouselSlide = (index: number): void => {
    setCurrentCarouselIndex(index);
    resetInterval();
  };

  const resetInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    startInterval();
  };

  const startInterval = () => {
    intervalRef.current = setInterval(() => {
      setCurrentCarouselIndex((prev) => (prev === 3 ? 0 : prev + 1));
    }, 10000);
  };

  useEffect(() => {
    const track = document.querySelector<HTMLElement>(".carousel-track");
    if (track) {
      track.style.transform = `translateX(-${currentCarouselIndex * 100}%)`;
    }

    const dots = document.querySelectorAll<HTMLElement>(".dot");
    dots.forEach((dot, index) => {
      dot.classList.toggle("active", index === currentCarouselIndex);
    });
  }, [currentCarouselIndex]);

  useEffect(() => {
    startInterval();
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPreloaderVisible(false);
    }, 500); // 0.5 seconds

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const token = getToken();

    if (token) {
      setTokenExists(true);
    } else {
      setTokenExists(false);
    }
  }, []);

  return (
    <div>
      <title>
        Managerize: All-in-one Solution For Field Employee Management
      </title>
      <link rel="stylesheet" href="css/bootstrap.min.css" />
      {/*====== PRELOADER PART START ======*/}
      <div className={`preloader ${preloaderVisible ? "" : "hide"}`}>
        <div className="loader">
          <div className="spinner">
            <div className="spinner-container">
              <div className="spinner-rotator">
                <div className="spinner-left">
                  <div className="spinner-circle" />
                </div>
                <div className="spinner-right">
                  <div className="spinner-circle" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*====== PRELOADER PART ENDS ======*/}

      {/*====== HEADER PART START ======*/}
      <BurgerMenu />
      <header className="header-area">
        <div className="navbar-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg">
                  <div className="navbar-brand">
                    <img src="images/logo/logo.svg" alt="Logo" />
                  </div>
                  <div
                    className="collapse navbar-collapse sub-menu-bar"
                    id="navbarSupportedContent"
                  >
                    <ul id="nav" className="navbar-nav ms-auto">
                      <li className="nav-item">
                        <a className="page-scroll" href="#pricing">
                          Pricing
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="page-scroll"
                          href="https://calendly.com/contact-managerize/30min"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Book a demo
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="page-scroll" href="/FAQ">
                          FAQ
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="page-scroll" href="/terms-and-conditions">
                          Terms And Conditions
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="page-scroll" href="/privacy-policy">
                          Privacy Policy
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* navbar collapse */}
                  <div className="navbar-btn d-sm-inline-block">
                    <a
                      className="main-btn"
                      data-scroll-nav={0}
                      href={tokenExists ? "/dashboard" : "/login"}
                      rel="nofollow"
                    >
                      {tokenExists ? "Dashboard" : "Login/Register"}
                    </a>
                  </div>
                </nav>
                {/* navbar */}
              </div>
            </div>
            {/* row */}
          </div>
          {/* container */}
        </div>
        {/* navbar area */}
        <div
          id="home"
          className="header-hero bg_cover"
          style={{ backgroundImage: "url(images/header/banner.png)" }}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="header-hero-content text-center">
                  <h1 className="header-sub-title wow fadeInUp">
                    More Efficiency. More Control.
                  </h1>
                  <h2 className="header-title wow fadeInUp">Guaranteed.</h2>
                  <p className="text wow fadeInUp">
                    Track employee performance, attendance, and details all in
                    one place. Simplify your HR tasks with our comprehensive
                    tools.
                  </p>
                  <a href="/register" className="main-btn wow fadeInUp">
                    Get Started
                  </a>
                </div>
                {/* header hero content */}
              </div>
            </div>
            {/* row */}
            <div className="row">
              <div className="col-lg-12">
                <div
                  className="header-hero-image text-center wow fadeIn"
                  data-wow-duration="1.3s"
                  data-wow-delay="1.4s"
                >
                  <img src="images/header/header-hero.png" alt="hero" />
                </div>
                {/* header hero image */}
              </div>
            </div>
            {/* row */}
          </div>
          {/* container */}
          <div id="particles-1" className="particles" />
        </div>
        {/* header hero */}
      </header>
      {/*====== HEADER PART ENDS ======*/}
      {/*====== BRAND PART START ======*/}
      {/*====== SERVICES PART START ======*/}
      <section id="features" className="services-area pt-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="section-title text-center pb-40">
                <div className="line m-auto" />
                <h3 className="title">So, How Do You Manage Your Workforce?</h3>
              </div>
              {/* section title */}
            </div>
          </div>
          {/* row */}
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-7 col-sm-8">
              <div className="single-services text-center mt-30">
                <div className="services-icon">
                  <img
                    className="shape"
                    src="images/services/services-shape.svg"
                    alt="shape"
                  />
                  <img
                    className="shape-1"
                    src="images/services/services-shape-1.svg"
                    alt="shape"
                  />
                  <i className="lni lni-baloon"> </i>
                </div>
                <div className="services-content mt-30">
                  <div className="services-title">
                    <h3>Hire More Administrative Staff?</h3>
                  </div>
                  <p className="text">
                    Finding the right individuals can be daunting. Training and
                    integrating new personnel can be costly. Even if you find
                    the ideal candidate, relying on just one person can be
                    risky!
                  </p>
                </div>
              </div>
              {/* single services */}
            </div>
            <div className="col-lg-4 col-md-7 col-sm-8">
              <div className="single-services text-center mt-30">
                <div className="services-icon">
                  <img
                    className="shape"
                    src="images/services/services-shape.svg"
                    alt="shape"
                  />
                  <img
                    className="shape-1"
                    src="images/services/services-shape-2.svg"
                    alt="shape"
                  />
                  <i className="lni lni-cog"> </i>
                </div>
                <div className="services-content mt-30">
                  <div className="services-title">
                    <h3>Manage Everything Yourself?</h3>
                  </div>
                  <p className="text">
                    Sure, this can work if your schedule is remarkably open.
                    However, if you&apos;re already overwhelmed... this simply
                    isn&apos;t practical.
                  </p>
                </div>
              </div>
              {/* single services */}
            </div>
            <div className="col-lg-4 col-md-7 col-sm-8">
              <div className="single-services text-center mt-30">
                <div className="services-icon">
                  <img
                    className="shape"
                    src="images/services/services-shape.svg"
                    alt="shape"
                  />
                  <img
                    className="shape-1"
                    src="images/services/services-shape-3.svg"
                    alt="shape"
                  />
                  <i className="lni lni-bolt-alt"> </i>
                </div>
                <div className="services-content mt-30">
                  <div className="services-title">
                    <h3>Invest in Costly Solutions?</h3>
                  </div>
                  <p className="text">
                    Not equipped with a massive management budget? Then you
                    might find your needs being sidelined, handled with minimal
                    attention and efficiency. Not exactly the strategic
                    advantage you deserve.
                  </p>
                </div>
              </div>
              {/* single services */}
            </div>
          </div>
          {/* row */}
        </div>
        {/* container */}
      </section>
      {/*====== SERVICES PART ENDS ======*/}
      <section id="about">
        <div className="carousel-title">
          <div className="col-lg-10">
            <div className="section-title text-center pb-40">
              <div className="line m-auto" />
              <h3 className="title">What Sets Managerize Apart?</h3>
            </div>
            {/* section title */}
          </div>
        </div>
        {/*====== ABOUT PART START ======*/}
        <div className="about-area">
          <div className="container">
            <div className="carousel-wrapper">
              <div className="carousel-container">
                <div className="carousel-track">
                  {/* Slide 1 */}
                  <div
                    className={`carousel-slide ${currentCarouselIndex === 0 ? "active" : ""}`}
                  >
                    <div className="slide-logo-container">
                      <img
                        src="/images/logo/logo.svg"
                        alt="Company Logo"
                        className="slide-logo"
                      />
                    </div>
                    <div className="slide-content">
                      <h3 className="slide-title">GUARANTEED</h3>
                      <div className="slide-text">
                        <p>
                          Your <strong>success</strong> is our{" "}
                          <strong>success</strong>.
                        </p>
                        <p>
                          That&apos;s the foundation of a{" "}
                          <strong>true partnership</strong>.
                        </p>
                        <p>We don&apos;t just leave you with all the risk,</p>
                        <p>
                          we&apos;re in this <strong>together</strong>.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Slide 2 */}
                  <div
                    className={`carousel-slide ${currentCarouselIndex === 1 ? "active" : ""}`}
                  >
                    <div className="slide-logo-container">
                      <img
                        src="/images/logo/logo.svg"
                        alt="Company Logo"
                        className="slide-logo"
                      />
                    </div>
                    <div className="slide-content">
                      <h3 className="slide-title">RESULTS</h3>
                      <div className="slide-text">
                        <p>
                          Your <strong>results</strong> are our{" "}
                          <strong>top priority</strong>.
                        </p>
                        <p>
                          We focus on <strong>delivering</strong>, not just
                          promising.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Slide 3 */}
                  <div
                    className={`carousel-slide ${currentCarouselIndex === 2 ? "active" : ""}`}
                  >
                    <div className="slide-logo-container">
                      <img
                        src="/images/logo/logo.svg"
                        alt="Company Logo"
                        className="slide-logo"
                      />
                    </div>
                    <div className="slide-content">
                      <h3 className="slide-title">ACCESSIBLE</h3>
                      <div className="slide-text">
                        <p>
                          We&apos;re <strong>not hidden</strong> behind layers
                          of bureaucracy.
                        </p>
                        <p>
                          As a local company, we&apos;re <strong>always</strong>{" "}
                          within reach when you need us.
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Slide 4 */}
                  <div
                    className={`carousel-slide ${currentCarouselIndex === 3 ? "active" : ""}`}
                  >
                    <div className="slide-logo-container">
                      <img
                        src="/images/logo/logo.svg"
                        alt="Company Logo"
                        className="slide-logo"
                      />
                    </div>
                    <div className="slide-content">
                      <h3 className="slide-title">SPECIALIZED</h3>
                      <div className="slide-text">
                        <p>
                          Being a jack of all trades isn&apos;t in our playbook.
                        </p>
                        <p>
                          We <strong>focus</strong> on industries we know,
                          ensuring <strong>excellence</strong>.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Navigation dots */}
                <div className="carousel-nav">
                  {[0, 1, 2, 3].map((index) => (
                    <button
                      key={index}
                      className={`nav-dot ${index === currentCarouselIndex ? "active" : ""}`}
                      onClick={() => goToCarouselSlide(index)}
                      aria-label={`View slide ${index + 1}`}
                    />
                  ))}
                </div>
              </div>
            </div>

            {/* row */}
          </div>
          {/* container */}
          <div className="about-shape-1">
            <img src="images/about/about-shape-1.svg" alt="shape" />
          </div>
        </div>
        {/*====== ABOUT PART ENDS ======*/}
      </section>
      {/*====== PRICING PART START ======*/}
      <section id="pricing">
        <div className="cards">
          <div className="card shadow">
            <div className="pricing-text">
              <div className="pack">Starter</div>
              <div id="basic" className="price bottom-bar">
                $49.99
              </div>
              <div className="bottom-bar">
                Up to <strong>7</strong> employees
              </div>
              <div className="bottom-bar">Attendance tracking</div>
              <div className="bottom-bar">Earnings reports</div>
              <div className="bottom-bar">Location tracking</div>
              <div className="bottom-bar">Schedule management</div>
              <div className="bottom-bar">Employee records</div>
              <div className="bottom-bar">Employee communication</div>
              <div className="bottom-bar">Task management</div>
              <a href="/register">
                <button className="pricing-btn">Get started</button>
              </a>
            </div>
          </div>
          <div className="card active">
            <div className="pricing-text">
              <div className="pack">Growth</div>
              <div id="professional" className="price bottom-bar">
                $99.99
              </div>
              <div className="bottom-bar">
                Up to <strong>15</strong> employees
              </div>
              <div className="bottom-bar">Attendance tracking</div>
              <div className="bottom-bar">Earnings reports</div>
              <div className="bottom-bar">Location tracking</div>
              <div className="bottom-bar">Schedule management</div>
              <div className="bottom-bar">Employee records</div>
              <div className="bottom-bar">Employee communication</div>
              <div className="bottom-bar">Task management</div>
              <a href="/register">
                <button className="active-btn">Get started</button>
              </a>
            </div>
          </div>
          <div className="card shadow">
            <div className="pricing-text">
              <div className="pack">Enterprise</div>
              <div id="basic" className="price bottom-bar">
                Custom
              </div>
              <div className="bottom-bar">
                <strong>Unlimited</strong> employees
              </div>
              <div className="bottom-bar">Attendance tracking</div>
              <div className="bottom-bar">Earnings reports</div>
              <div className="bottom-bar">Location tracking</div>
              <div className="bottom-bar">Schedule management</div>
              <div className="bottom-bar">Employee records</div>
              <div className="bottom-bar">Employee communication</div>
              <div className="bottom-bar">Task management</div>
              <a
                href="https://calendly.com/contact-managerize/30min"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button className="pricing-btn">Contact us</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      {/*====== PRICING PART END ======*/}
      {/*====== FOOTER PART START ======*/}
      <footer
        id="footer"
        className="footer-area pt-120"
        style={{
          backgroundImage: "url(/images/footer/footer-bg.svg)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <section id="demo"></section>
          <div
            className="subscribe-area wow fadeIn"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            <div className="row">
              <div className="col-lg-6">
                <div className="subscribe-content mt-45">
                  <h2 className="subscribe-title">
                    Want to see a Demo? <span>Get in touch!</span>
                  </h2>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="subscribe-form mt-50">
                  <input type="text" id="email" placeholder="Enter email" />
                  <a
                    href="https://calendly.com/contact-managerize/30min"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="main-btn">Submit</button>
                  </a>
                </div>
              </div>
            </div>
            {/* row */}
          </div>
          {/* subscribe area */}
          <div className="footer-widget pb-100">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-8">
                <div
                  className="footer-about mt-50 wow fadeIn"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="logo">
                    <img src="images/logo/logo.svg" alt="logo" />
                  </div>
                </div>
                {/* footer about */}
              </div>
              <div className="col-lg-5 col-md-7 col-sm-12">
                <div className="footer-link d-flex mt-50 justify-content-sm-between"></div>
                {/* footer link */}
              </div>
              <div className="col-lg-3 col-md-5 col-sm-12">
                <div
                  className="footer-contact mt-50 wow fadeIn"
                  data-wow-duration="1s"
                  data-wow-delay="0.8s"
                >
                  <div className="footer-title">
                    <h4 className="title">Contact Us</h4>
                  </div>
                  <ul className="contact">
                    <li>
                      <strong>E-mail:</strong>
                      <br></br>contact@managerize.com
                    </li>
                    <li>
                      <strong>Adress:</strong>
                      <br></br>75 East 3rd Street<br></br>Sheridan, WY<br></br>
                      82801
                    </li>
                  </ul>
                </div>
                {/* footer contact */}
              </div>
            </div>
            {/* row */}
          </div>
          {/* footer widget */}
          <div className="footer-copyright">
            <div className="row">
              <div className="col-lg-12">
                <div className="copyright d-sm-flex justify-content-between">
                  <div className="copyright-content">
                    <p className="text">Developed by Nikologic</p>
                  </div>
                  {/* copyright content */}
                </div>
                {/* copyright */}
              </div>
            </div>
            {/* row */}
          </div>
          {/* footer copyright */}
        </div>
        {/* container */}
        <div id="particles-2" />
      </footer>
      {/*====== FOOTER PART ENDS ======*/}
    </div>
  );
};

export default Home;
